.accordion-collapse {
    overflow: hidden;
    transition: max-height 0.3s ease, padding 0.3s ease;
    max-height: 0;
    padding: 0;
  }
  
  .accordion-collapse.show {
    max-height: 500px; /* Adjust according to the content height */
    padding: 1rem; /* Match your content's padding */
  }
  